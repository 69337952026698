body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Regular */
@font-face {
    font-family: 'OpenSans';
    src: url('assets/Open_Sans/OpenSans-Regular.ttf');
    /* src fallbacks ommitted for brevity */
    font-weight: normal;
    font-weight: 400;
    font-style: normal;
}
  @font-face {
    font-family: 'OpenSans-Regular';
    src: url('assets/Open_Sans/OpenSans-Regular.ttf');
    /* src fallbacks ommitted for brevity */
    font-weight: normal;
    font-weight: 400;
    font-style: normal;
}

/* Bold */
@font-face {
    font-family: 'OpenSans-Bold';
    src: url('assets/Open_Sans/OpenSans-Bold.ttf');
    /* src fallbacks ommitted for brevity */
    font-weight: 700;
    font-style: normal;
}
